@import "../../styles/default.sizes.module";

.alert-component {
  width: 100%;

  .ui.message {
    &.alert-component-message {
      .content {
        width: 100%;
        padding-right: $spacing-08 !important;
      }

      .icon.close {
        position: absolute !important;
        top: 0.85rem !important;
        right: 1rem !important;
        margin: 0 !important;
      }
    }
  }
}
