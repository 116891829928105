@import "../../../styles/colors.module";
@import "../../../styles/sizes.module";

.side-menu {
  position: sticky;
  top: 0;
  height: 100vh;
  min-width: $menu-navigation-width !important;
  width: $menu-navigation-width !important;
  background-color: $side-menu-background-color !important;

  .side-menu-accordion-icon {
    margin-left: auto !important;
    margin-right: unset !important;
  }

  .side-menu-header {
    height: 4rem;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing-09 !important;
    background-color: $side-menu-logo-background-color !important;
  }

  // Liens simples du menu
  .item {
    display: flex !important;
    align-items: center;
    height: 2.5rem;
    padding: $spacing-04 $spacing-03 $spacing-04 $spacing-05 !important;
    margin-bottom: $spacing-02 !important;
    margin-right: $spacing-04 !important;
    line-height: $spacing-06 !important;
    border-right-style: unset !important;
  }

  .item:not(.side-menu-accordion-active, .active) {
    color: $side-menu-text-color !important;
  }

  span {
    line-height: 120%;
  }

  // Pour les items des menus déroulants
  .side-menu-main-menu-sub-item {
    padding-left: 3.45rem !important;

    span {
      font-weight: normal !important;
    }
  }

  // Personnalisation des icones
  .side-menu-icon {
    float: unset !important;
    margin-right: $spacing-03 !important;
    margin-left: $spacing-00 !important;
  }
  .side-menu-icon,
  .side-menu-accordion-icon {
    font-size: 1.5rem;
    width: 1.5rem;
  }
}

// En tête des menus
.ui.secondary.pointing.menu .header.item {
  height: 3rem !important;
  color: rgba($side-menu-text-color, 0.5) !important;
  font-size: 0.875rem;
  padding: $spacing-06 $spacing-05 $spacing-04 $spacing-05;
}

// Au survol des liens simples du menu
.ui.secondary.vertical.pointing.menu .item:not(.header.item, .active):hover {
  border-radius: 0rem 1.5rem 1.5rem 0rem !important;
  border: unset !important;
  margin-right: $spacing-04 !important;
}

// Lorsqu'un lien simple est actif
.ui.secondary.vertical.pointing.menu .active {
  border-radius: 0rem 1.5rem 1.5rem 0rem !important;
  margin-right: $spacing-04 !important;
  border: unset !important;
}

// Suppression de la bordure blanche
.ui.attached.menu:not(.tabular) {
  border: unset !important;
}

// Spécificités du light mode
.light-background {
  span {
    font-weight: 700 !important;
  }

  .active {
    background-color: $light-blue-200 !important;
    color: $side-menu-text-color !important;
  }

  .item:not(.header.item, .active):hover {
    background-color: $main-light-color !important;
  }

  .side-menu-accordion-active {
    color: $light-blue-700 !important;
  }
}

// Spécificités du dark mode
.dark-background {
  .active {
    background-color: $main-light-color !important;
    color: $main-color !important;
  }

  .item:not(.header.item, .active):hover {
    background-color: rgba($side-menu-text-color, 0.2) !important;
  }

  .side-menu-accordion-active {
    color: $light-blue-700 !important;
  }
}
