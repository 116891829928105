@import "../../../styles/sizes.module";
@import "../../../styles/colors.module";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $footer-height;
  width: 100%;
  font-size: 0.875rem;
  grid-column-gap: 2.25rem;

  @media (max-width: $mobile-width) {
    height: unset;
    font-size: 1rem;
    flex-direction: column;
    padding-bottom: 1.5rem;

    .footer-link:first-child {
      padding-top: 1.5rem !important;
    }
  }

  .footer-link {
    padding: 0.75rem 1rem;

    @media (max-width: $mobile-width) {
      padding-top: 2rem;
      padding-bottom: unset;
    }
  }
}
