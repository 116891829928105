@import "../../styles/_default.colors.module";
@import "../../styles/_default.sizes.module";

.accordion {
  border-radius: $radius-m !important;
  border: 1px solid $neutral-300;
  box-shadow: unset !important;

  .title {
    display: flex;
    align-items: center;
    font-size: 1rem !important;
    color: $dark-blue-800 !important;
    font-weight: bold;
    border-radius: $radius-m;
    padding: $spacing-04 $spacing-05 !important;

    .fa-caret-down {
      font-size: 1.25rem;
      margin-right: $spacing-04;
      transform: rotate(270deg);
    }

    .right-component {
      margin-left: auto;
    }
  }

  .title.active {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;

    i {
      transform: unset;
    }
  }

  .content {
    padding: $spacing-06 $spacing-06 $spacing-08 $spacing-06 !important;

    // Pour gérer les accordéons sticked situés dans un autre accordéon
    .sticked {
      .accordion {
        margin-top: 0 !important;
      }
      &:first-child {
        .accordion:not(.borderless) {
          .title {
            border-top-left-radius: $radius-m !important;
            border-top-right-radius: $radius-m !important;
          }
        }
      }
      &:last-child {
        .accordion:not(.borderless) {
          .title {
            border-bottom-left-radius: $radius-m !important;
            border-bottom-right-radius: $radius-m !important;
          }
        }
      }
    }
  }

  &.borderless {
    border-radius: $radius-none !important;
    border: none !important;

    .title {
      border-radius: $radius-none !important;
    }
  }
}

.sticked,
.sticked > .column {
  margin-top: $spacing-00 !important;
  margin-bottom: $spacing-00 !important;
  padding-top: $spacing-00 !important;
  padding-bottom: $spacing-00 !important;
}
.sticked:not(:first-child) {
  .accordion,
  .accordion > .title {
    border-top-left-radius: $radius-none !important;
    border-top-right-radius: $radius-none !important;
  }
}
.sticked:not(:last-child) {
  .accordion,
  .accordion > .title {
    border-bottom: none !important;
    border-bottom-left-radius: $radius-none !important;
    border-bottom-right-radius: $radius-none !important;
  }
}
