@import "../../styles/default.sizes.module";

.cookies-modal {
  .informations-link {
    margin-bottom: $spacing-07;
  }

  @media (max-width: $mobile-width) {
    .accordion .title.active .right-component {
      display: none; // On retire le bouton "Obligatoire" du titre quand l'accordéon est déplié
    }
  }

  @media (max-width: 470px) {
    .accordion .title {
      flex-wrap: wrap;
      row-gap: $spacing-03;

      .right-component {
        margin-left: unset;
      }
    }
  }
}
