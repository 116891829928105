@import "../../styles/default.shadows.module";
@import "../../../styles/sizes.module";
@import "../../../styles/colors.module";

.icon-button {
  display: flex !important;
  margin: 0 !important;
  justify-content: center;
  align-items: center;

  &.variant-default,
  &.variant-action,
  &.variant-action-warning {
    background-color: transparent !important;
    border-radius: $radius-full;
    border: none;
    color: $dark-blue-800;
    height: 1.5rem;
    padding: $spacing-01 !important;
    width: 1.5rem;

    // On spécifie un comportement au survol seulement si le device le permet
    @media (hover: hover) {
      &:hover {
        background-color: $light-blue-200 !important;
      }
    }
  }

  &.variant-action {
    color: $light-blue-600;

    @media (hover: hover) {
      &:hover {
        color: $light-blue-600 !important;
      }
    }
  }

  &.variant-action-warning {
    color: $error-700;

    @media (hover: hover) {
      &:hover {
        color: $error-700 !important;
        background-color: $error-50 !important;
      }
    }
  }

  &.variant-action,
  &.variant-action-warning {
    &.disabled {
      color: $neutral-400 !important;
    }
  }

  &.variant-dark-blue-square {
    background-color: $dark-blue-800;
    border-radius: $radius-s;
    border: 1px solid $dark-blue-800;
    color: $white;
    height: 2.5rem;
    padding: $spacing-03 !important;
    width: 2.5rem;

    &:hover,
    &:active,
    &:focus {
      background-color: $dark-blue-800;
      color: $white;
    }
  }

  .icon {
    opacity: 1 !important;
    margin: 0 !important;
  }
  .icon::before {
    font-size: 1.25rem !important;
  }
}
