@import "../../styles/default.colors.module";
@import "../../styles/default.sizes.module";
@import "../../styles/default.shadows.module";

.modal {
  .modal-header,
  .modal-content,
  .modal-actions {
    background-color: $neutral-50 !important;
  }

  .modal-header {
    font-size: 1.25rem !important;
    color: $dark-blue-800 !important;

    .icon {
      float: right;
      cursor: pointer;
      margin: 0.1rem 0;
    }
  }

  .modal-actions {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    text-align: end;
    justify-content: end;
    padding: 1rem 1.5rem !important;

    @media (max-width: $mobile-width) {
      flex-direction: column;

      .button {
        margin-left: 0 !important;
        margin-right: 0 !important;

        &:not(:last-child) {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .ui.page.dimmer.modals {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  &.scrolling {
    .modal-content {
      box-shadow: $shadow-m-modal !important;
    }
  }

  &.small-content {
    .modal-header {
      font-size: 1rem !important;
    }
    .modal-content {
      font-size: 0.875rem !important;
    }
  }

  &:not(.mini, .tiny, .small, .large, .fullscreen) {
    width: unset !important;
    min-width: 33vw !important;
    max-width: 50vw !important;

    @media (max-width: $mobile-width) {
      max-width: 90vw !important;
      min-width: 16rem !important;
    }
  }
}
