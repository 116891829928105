@import "../../../styles/sizes.module";

$default-width: 13rem;
$small-screen-diff: 2rem;

.responsive-logo {
  display: flex;
  height: 100%;

  @media (max-width: $small-screen-width) {
    margin: unset !important;
  }

  &.variant-default {
    max-width: $default-width;

    @media (max-width: $small-screen-width) {
      max-width: calc($default-width - $small-screen-diff);
    }

    &.fixed-width {
      width: $default-width;
      min-width: $default-width;

      @media (max-width: $small-screen-width) {
        width: calc($default-width - $small-screen-diff);
        min-width: calc($default-width - $small-screen-diff);
      }
    }
  }

  &.variant-header {
    padding: $spacing-03;
  }

  img {
    max-height: 100% !important;
    max-width: 100% !important;
    margin: auto;
  }
}
