@import "../../styles/default.colors.module";

.link-component {
  display: inline;
  color: $text-link-color !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
  text-decoration: underline;
  text-decoration-color: $text-link-color !important;

  &:hover {
    color: $text-link-color !important;
    text-decoration: unset !important;
    cursor: pointer;
  }

  &:active {
    color: $text-link-active-color !important;
    text-decoration: unset !important;
    cursor: pointer;
  }

  &.disabled {
    color: $text-link-disabled-color !important;
    cursor: default !important;
    text-decoration: unset;
  }

  &.variant-dark {
    color: $footer-text-dark-color !important;
    text-decoration-color: $footer-text-dark-color !important;

    &:hover,
    &:active,
    &.disabled {
      color: $footer-text-dark-color !important;
      text-decoration-color: $footer-text-dark-color !important;
    }
  }

  &.variant-light {
    color: $footer-text-light-color !important;
    text-decoration-color: $footer-text-light-color !important;

    &:hover,
    &:active,
    &.disabled {
      color: $footer-text-light-color !important;
      text-decoration-color: $footer-text-light-color !important;
    }
  }
}
