@import "../../styles/default.sizes.module";

.header-component {
  padding: 0.5rem 2rem;
  height: $header-height;
  min-height: $header-height;
  overflow: hidden;

  .header-component-row {
    height: calc($header-height + 1rem);
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    overflow: hidden;

    .header-component-column {
      width: unset !important;
      min-width: 0 !important;
    }

    h2 {
      margin-bottom: unset;
      line-height: 1.875rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h4 {
      margin-top: unset !important;
      line-height: 1.2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .header-component-links {
      display: flex !important;
      justify-content: flex-end;

      i.icon {
        margin: unset !important;
        width: unset !important;
      }
    }
  }

  @media (max-width: $mobile-width) {
    height: $header-mobile-height;
    min-height: $header-mobile-height;

    .header-component-row {
      height: calc($header-mobile-height + 1rem);

      .header-component-column {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      h4 {
        font-size: 1.143rem;
      }
    }
  }
}
