@import "../../../styles/colors.module";
@import "../../../styles/sizes.module";

.side-menu-mobile {
  background-color: $side-menu-background-color;
  padding-top: calc($header-secondary-mobile-height + $spacing-06);

  .side-menu {
    width: 100% !important;
  }
}
