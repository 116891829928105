@import "../../../styles/sizes.module";
@import "../../../styles/colors.module";
@import "../../styles/default.shadows.module";

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Assurez-vous que le loader est au-dessus du contenu */

}



