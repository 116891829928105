@import "../../../styles/colors.module";

.header-menu-popup {
  padding: 0.7rem !important;

  .menu {
    width: unset !important;
    min-width: 10rem !important;

    .header {
      color: $neutral-500;
    }

    .item:hover:not(.header) {
      text-decoration: underline !important;
      background: $white !important;
      border-radius: 0 !important;
    }
  }
}

.header-menu-popup-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.25);
}
