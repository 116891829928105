@import "../../styles/sizes.module";
@import "../../styles/colors.module";

.login-scene {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - $header-disconnected-total-height - $footer-height);
  margin: -$spacing-07;

  @media (max-width: $mobile-width) {
    min-height: calc(100vh - $header-disconnected-mobile-total-height);
    margin: -$spacing-05;
  }

  .login-scene-content {
    .login-scene-content-header {
      min-height: 8rem;

      @media (max-width: $mobile-width) {
        min-height: $spacing-06;
      }
    }

    .login-scene-content-column {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .login-scene-authentication,
      .login-scene-register {
        width: $login-form-width;
        display: flex;
        flex-direction: column;

        .login-scene-register-contact {
          text-align: center;
          margin-top: $spacing-12;
          color: $dark-blue-800;

          .button-tertiary,
          .button-tertiary button {
            margin: 0;
            padding: 0 !important;
          }
        }
      }
    }
  }

  .login-scene-footer img {
    width: 100%;
  }
}
