@import "../../styles/default.sizes.module";

.ui.message {
  padding: $spacing-05 !important;
  align-items: flex-start !important;
  .header-container {
    display: flex !important;
    align-items: center;
    .icon.information {
      margin-right: $spacing-03;
    }
  }
  .header + p,
  .list:not(.ui) {
    margin-top: $spacing-03 !important;
  }
}

.ui.message.icon-message {
  .header + p,
  .list:not(.ui) {
    margin-left: 33px;
  }
}
