.error.field > .input-file > .ui.action.file.input {
  border: 1px solid #e0b4b4 !important;
  border-radius: 0.28571429rem;
}

.error.field > .input-file > .ui.action.file.input > label {
  // Sinon la bordure d'erreur n'est pas collée à la zone d'input
  margin-bottom: 0 !important;
}

.error.field > .input-file > .ui.action.file.input > label:first-child {
  border: 0;
  background-color: #fff6f6;
  color: #f2d7e0;
}

.error.field > .small.right.labeled.input > .ui.label {
  border: 1px solid #e0b4b4 !important;
  background-color: #fff6f6;
  color: #f2d7e0;
}

.ui.basic.label.semantic-form-field-label {
  margin: 5px;
}

.small,
.small * {
  font-size: 0.875rem !important;
}

.checkbox.small > label::before,
.small > .radio.checkbox > label::before {
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.checkbox.small:not(.radio) > label::after {
  top: -0.1rem !important;
  width: 0.875rem !important;
}

.small > .radio.checkbox > label::after,
.small.ui.radio.checkbox label::after {
  top: 0.05rem !important;
  width: 0.875rem !important;
}
