.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fasr,
.fal,
.fa-light,
.fasl,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro'; }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands'; }

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: 'Font Awesome 6 Duotone'; }

.fass,
.fasr,
.fasl,
.fa-sharp {
  font-family: 'Font Awesome 6 Sharp'; }

.fass,
.fa-sharp {
  font-weight: 900; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s; } }

@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-add::before { content: "\2b"; }
.fa-address-book::before { content: "\f2b9"; }
.fa-address-card::before { content: "\f2bb"; }
.fa-adjust::before { content: "\f042"; }
.fa-angle-double-down::before { content: "\f103"; }
.fa-angle-double-left::before { content: "\f100"; }
.fa-angle-double-right::before { content: "\f101"; }
.fa-angle-double-up::before { content: "\f102"; }
.fa-angle-down::before { content: "\f107"; }
.fa-angle-left::before { content: "\f104"; }
.fa-angle-right::before { content: "\f105"; }
.fa-angle-up::before { content: "\f106"; }
.fa-angles-down::before { content: "\f103"; }
.fa-angles-left::before { content: "\f100"; }
.fa-angles-right::before { content: "\f101"; }
.fa-angles-up::before { content: "\f102"; }
.fa-arrow-down::before { content: "\f063"; }
.fa-arrow-down-a-z::before { content: "\f15d"; }
.fa-arrow-down-arrow-up::before { content: "\f883"; }
.fa-arrow-down-left-and-arrow-up-right-to-center::before { content: "\e092"; }
.fa-arrow-down-wide-short::before { content: "\f160"; }
.fa-arrow-down-z-a::before { content: "\f881"; }
.fa-arrow-left::before { content: "\f060"; }
.fa-arrow-left-rotate::before { content: "\f0e2"; }
.fa-arrow-right::before { content: "\f061"; }
.fa-arrow-right-arrow-left::before { content: "\f0ec"; }
.fa-arrow-right-from-bracket::before { content: "\f08b"; }
.fa-arrow-right-to-bracket::before { content: "\f090"; }
.fa-arrow-rotate-back::before { content: "\f0e2"; }
.fa-arrow-rotate-backward::before { content: "\f0e2"; }
.fa-arrow-rotate-left::before { content: "\f0e2"; }
.fa-arrow-turn-down-right::before { content: "\e3d6"; }
.fa-arrow-turn-right::before { content: "\f064"; }
.fa-arrow-up::before { content: "\f062"; }
.fa-arrow-up-arrow-down::before { content: "\e099"; }
.fa-arrow-up-left::before { content: "\e09d"; }
.fa-arrow-up-right::before { content: "\e09f"; }
.fa-arrow-up-right-and-arrow-down-left-from-center::before { content: "\e0a0"; }
.fa-arrow-up-right-from-square::before { content: "\f08e"; }
.fa-arrow-up-short-wide::before { content: "\f885"; }
.fa-arrow-up-wide-short::before { content: "\f161"; }
.fa-arrow-up-z-a::before { content: "\f882"; }
.fa-arrows::before { content: "\f047"; }
.fa-arrows-h::before { content: "\f07e"; }
.fa-arrows-left-right::before { content: "\f07e"; }
.fa-arrows-maximize::before { content: "\f31d"; }
.fa-arrows-minimize::before { content: "\e0a5"; }
.fa-arrows-repeat::before { content: "\f364"; }
.fa-arrows-rotate::before { content: "\f021"; }
.fa-arrows-up-down::before { content: "\f07d"; }
.fa-arrows-up-down-left-right::before { content: "\f047"; }
.fa-arrows-v::before { content: "\f07d"; }
.fa-assistive-listening-systems::before { content: "\f2a2"; }
.fa-automobile::before { content: "\f1b9"; }
.fa-baby::before { content: "\f77c"; }
.fa-baby-carriage::before { content: "\f77d"; }
.fa-backspace::before { content: "\f55a"; }
.fa-bag-shopping::before { content: "\f290"; }
.fa-bars::before { content: "\f0c9"; }
.fa-bars-sort::before { content: "\e0ae"; }
.fa-basket-shopping::before { content: "\f291"; }
.fa-bath::before { content: "\f2cd"; }
.fa-bathtub::before { content: "\f2cd"; }
.fa-battery::before { content: "\f240"; }
.fa-battery-0::before { content: "\f244"; }
.fa-battery-1::before { content: "\e0b1"; }
.fa-battery-2::before { content: "\f243"; }
.fa-battery-3::before { content: "\f242"; }
.fa-battery-4::before { content: "\f241"; }
.fa-battery-5::before { content: "\f240"; }
.fa-battery-bolt::before { content: "\f376"; }
.fa-battery-empty::before { content: "\f244"; }
.fa-battery-exclamation::before { content: "\e0b0"; }
.fa-battery-full::before { content: "\f240"; }
.fa-battery-half::before { content: "\f242"; }
.fa-battery-low::before { content: "\e0b1"; }
.fa-battery-quarter::before { content: "\f243"; }
.fa-battery-slash::before { content: "\f377"; }
.fa-battery-three-quarters::before { content: "\f241"; }
.fa-bell::before { content: "\f0f3"; }
.fa-bell-slash::before { content: "\f1f6"; }
.fa-blind::before { content: "\f29d"; }
.fa-bolt::before { content: "\f0e7"; }
.fa-bookmark::before { content: "\f02e"; }
.fa-box::before { content: "\f466"; }
.fa-box-open::before { content: "\f49e"; }
.fa-briefcase::before { content: "\f0b1"; }
.fa-brightness::before { content: "\e0c9"; }
.fa-bus-alt::before { content: "\f55e"; }
.fa-bus-simple::before { content: "\f55e"; }
.fa-cab::before { content: "\f1ba"; }
.fa-calendar::before { content: "\f133"; }
.fa-calendar-alt::before { content: "\f073"; }
.fa-calendar-days::before { content: "\f073"; }
.fa-camera::before { content: "\f030"; }
.fa-camera-alt::before { content: "\f030"; }
.fa-car::before { content: "\f1b9"; }
.fa-car-side::before { content: "\f5e4"; }
.fa-caret-circle-down::before { content: "\f32d"; }
.fa-caret-circle-left::before { content: "\f32e"; }
.fa-caret-circle-right::before { content: "\f330"; }
.fa-caret-circle-up::before { content: "\f331"; }
.fa-caret-down::before { content: "\f0d7"; }
.fa-caret-left::before { content: "\f0d9"; }
.fa-caret-right::before { content: "\f0da"; }
.fa-caret-up::before { content: "\f0d8"; }
.fa-carriage-baby::before { content: "\f77d"; }
.fa-cart-shopping::before { content: "\f07a"; }
.fa-chain::before { content: "\f0c1"; }
.fa-chain-broken::before { content: "\f127"; }
.fa-chain-slash::before { content: "\f127"; }
.fa-check::before { content: "\f00c"; }
.fa-check-circle::before { content: "\f058"; }
.fa-chevron-circle-down::before { content: "\f13a"; }
.fa-chevron-circle-left::before { content: "\f137"; }
.fa-chevron-circle-right::before { content: "\f138"; }
.fa-chevron-circle-up::before { content: "\f139"; }
.fa-chevron-double-down::before { content: "\f322"; }
.fa-chevron-double-left::before { content: "\f323"; }
.fa-chevron-double-right::before { content: "\f324"; }
.fa-chevron-double-up::before { content: "\f325"; }
.fa-chevron-down::before { content: "\f078"; }
.fa-chevron-left::before { content: "\f053"; }
.fa-chevron-right::before { content: "\f054"; }
.fa-chevron-up::before { content: "\f077"; }
.fa-chevrons-down::before { content: "\f322"; }
.fa-chevrons-left::before { content: "\f323"; }
.fa-chevrons-right::before { content: "\f324"; }
.fa-chevrons-up::before { content: "\f325"; }
.fa-child::before { content: "\f1ae"; }
.fa-children::before { content: "\e4e1"; }
.fa-circle-caret-down::before { content: "\f32d"; }
.fa-circle-caret-left::before { content: "\f32e"; }
.fa-circle-caret-right::before { content: "\f330"; }
.fa-circle-caret-up::before { content: "\f331"; }
.fa-circle-check::before { content: "\f058"; }
.fa-circle-chevron-down::before { content: "\f13a"; }
.fa-circle-chevron-left::before { content: "\f137"; }
.fa-circle-chevron-right::before { content: "\f138"; }
.fa-circle-chevron-up::before { content: "\f139"; }
.fa-circle-divide::before { content: "\e106"; }
.fa-circle-ellipsis::before { content: "\e10a"; }
.fa-circle-euro::before { content: "\e5ce"; }
.fa-circle-exclamation::before { content: "\f06a"; }
.fa-circle-half-stroke::before { content: "\f042"; }
.fa-circle-info::before { content: "\f05a"; }
.fa-circle-location-arrow::before { content: "\f602"; }
.fa-circle-minus::before { content: "\f056"; }
.fa-circle-plus::before { content: "\f055"; }
.fa-circle-question::before { content: "\f059"; }
.fa-circle-small::before { content: "\e122"; }
.fa-circle-user::before { content: "\f2bd"; }
.fa-circle-xmark::before { content: "\f057"; }
.fa-clipboard::before { content: "\f328"; }
.fa-clock::before { content: "\f017"; }
.fa-clock-four::before { content: "\f017"; }
.fa-close::before { content: "\f00d"; }
.fa-cloud::before { content: "\f0c2"; }
.fa-cloud-arrow-down::before { content: "\f0ed"; }
.fa-cloud-arrow-up::before { content: "\f0ee"; }
.fa-cloud-download::before { content: "\f0ed"; }
.fa-cloud-download-alt::before { content: "\f0ed"; }
.fa-cloud-upload::before { content: "\f0ee"; }
.fa-cloud-upload-alt::before { content: "\f0ee"; }
.fa-cog::before { content: "\f013"; }
.fa-cogs::before { content: "\f085"; }
.fa-coins::before { content: "\f51e"; }
.fa-comment-sms::before { content: "\f7cd"; }
.fa-compress::before { content: "\f066"; }
.fa-compress-alt::before { content: "\f422"; }
.fa-compress-arrows::before { content: "\e0a5"; }
.fa-contact-book::before { content: "\f2b9"; }
.fa-contact-card::before { content: "\f2bb"; }
.fa-cookie-bite::before { content: "\f564"; }
.fa-copy::before { content: "\f0c5"; }
.fa-couch-small::before { content: "\f4cc"; }
.fa-credit-card::before { content: "\f09d"; }
.fa-credit-card-alt::before { content: "\f09d"; }
.fa-deaf::before { content: "\f2a4"; }
.fa-deafness::before { content: "\f2a4"; }
.fa-delete-left::before { content: "\f55a"; }
.fa-desktop::before { content: "\f390"; }
.fa-desktop-alt::before { content: "\f390"; }
.fa-divide::before { content: "\f529"; }
.fa-down-left-and-up-right-to-center::before { content: "\f422"; }
.fa-download::before { content: "\f019"; }
.fa-drivers-license::before { content: "\f2c2"; }
.fa-ear-deaf::before { content: "\f2a4"; }
.fa-ear-listen::before { content: "\f2a2"; }
.fa-edit::before { content: "\f044"; }
.fa-ellipsis::before { content: "\f141"; }
.fa-ellipsis-h::before { content: "\f141"; }
.fa-ellipsis-v::before { content: "\f142"; }
.fa-ellipsis-vertical::before { content: "\f142"; }
.fa-envelope::before { content: "\f0e0"; }
.fa-envelope-badge::before { content: "\e16f"; }
.fa-envelope-dot::before { content: "\e16f"; }
.fa-envelope-open::before { content: "\f2b6"; }
.fa-equals::before { content: "\3d"; }
.fa-eur::before { content: "\f153"; }
.fa-euro::before { content: "\f153"; }
.fa-euro-sign::before { content: "\f153"; }
.fa-exchange::before { content: "\f0ec"; }
.fa-exclamation-circle::before { content: "\f06a"; }
.fa-exclamation-triangle::before { content: "\f071"; }
.fa-expand::before { content: "\f065"; }
.fa-expand-alt::before { content: "\f424"; }
.fa-expand-arrows::before { content: "\f31d"; }
.fa-external-link::before { content: "\f08e"; }
.fa-eye::before { content: "\f06e"; }
.fa-eye-low-vision::before { content: "\f2a8"; }
.fa-eye-slash::before { content: "\f070"; }
.fa-face-smile::before { content: "\f118"; }
.fa-family::before { content: "\e300"; }
.fa-file::before { content: "\f15b"; }
.fa-file-alt::before { content: "\f15c"; }
.fa-file-contract::before { content: "\f56c"; }
.fa-file-edit::before { content: "\f31c"; }
.fa-file-excel::before { content: "\f1c3"; }
.fa-file-invoice::before { content: "\f570"; }
.fa-file-lines::before { content: "\f15c"; }
.fa-file-pdf::before { content: "\f1c1"; }
.fa-file-pen::before { content: "\f31c"; }
.fa-file-signature::before { content: "\f573"; }
.fa-file-text::before { content: "\f15c"; }
.fa-file-word::before { content: "\f1c2"; }
.fa-file-zip::before { content: "\e5ee"; }
.fa-filter::before { content: "\f0b0"; }
.fa-floppy-disk::before { content: "\f0c7"; }
.fa-folder::before { content: "\f07b"; }
.fa-folder-blank::before { content: "\f07b"; }
.fa-folder-open::before { content: "\f07c"; }
.fa-font::before { content: "\f031"; }
.fa-gear::before { content: "\f013"; }
.fa-gears::before { content: "\f085"; }
.fa-gift::before { content: "\f06b"; }
.fa-graduation-cap::before { content: "\f19d"; }
.fa-grid::before { content: "\e195"; }
.fa-grid-2::before { content: "\e196"; }
.fa-grid-3::before { content: "\e195"; }
.fa-grip-dots-vertical::before { content: "\e411"; }
.fa-hand-holding-hand::before { content: "\e4f7"; }
.fa-hand-holding-seedling::before { content: "\f4bf"; }
.fa-hard-of-hearing::before { content: "\f2a4"; }
.fa-headphones::before { content: "\f025"; }
.fa-headphones-alt::before { content: "\f58f"; }
.fa-headphones-simple::before { content: "\f58f"; }
.fa-heart::before { content: "\f004"; }
.fa-home::before { content: "\f015"; }
.fa-home-alt::before { content: "\f015"; }
.fa-home-lg-alt::before { content: "\f015"; }
.fa-hospital::before { content: "\f0f8"; }
.fa-hospital-alt::before { content: "\f0f8"; }
.fa-hospital-wide::before { content: "\f0f8"; }
.fa-house::before { content: "\f015"; }
.fa-house-chimney-window::before { content: "\e00d"; }
.fa-house-medical::before { content: "\e3b2"; }
.fa-id-card::before { content: "\f2c2"; }
.fa-image::before { content: "\f03e"; }
.fa-inbox::before { content: "\f01c"; }
.fa-info-circle::before { content: "\f05a"; }
.fa-key::before { content: "\f084"; }
.fa-laptop::before { content: "\f109"; }
.fa-laptop-mobile::before { content: "\f87a"; }
.fa-layer-group::before { content: "\f5fd"; }
.fa-link::before { content: "\f0c1"; }
.fa-link-slash::before { content: "\f127"; }
.fa-list::before { content: "\f03a"; }
.fa-list-1-2::before { content: "\f0cb"; }
.fa-list-dots::before { content: "\f0ca"; }
.fa-list-numeric::before { content: "\f0cb"; }
.fa-list-ol::before { content: "\f0cb"; }
.fa-list-squares::before { content: "\f03a"; }
.fa-list-ul::before { content: "\f0ca"; }
.fa-loader::before { content: "\e1d4"; }
.fa-location::before { content: "\f601"; }
.fa-location-arrow::before { content: "\f124"; }
.fa-location-circle::before { content: "\f602"; }
.fa-location-crosshairs::before { content: "\f601"; }
.fa-location-dot::before { content: "\f3c5"; }
.fa-lock::before { content: "\f023"; }
.fa-lock-open::before { content: "\f3c1"; }
.fa-loveseat::before { content: "\f4cc"; }
.fa-low-vision::before { content: "\f2a8"; }
.fa-magnifying-glass::before { content: "\f002"; }
.fa-magnifying-glass-minus::before { content: "\f010"; }
.fa-magnifying-glass-plus::before { content: "\f00e"; }
.fa-mail-forward::before { content: "\f064"; }
.fa-mail-reply::before { content: "\f3e5"; }
.fa-mail-reply-all::before { content: "\f122"; }
.fa-male::before { content: "\f183"; }
.fa-map-location-dot::before { content: "\f5a0"; }
.fa-map-marked-alt::before { content: "\f5a0"; }
.fa-map-marker-alt::before { content: "\f3c5"; }
.fa-microphone::before { content: "\f130"; }
.fa-microphone-slash::before { content: "\f131"; }
.fa-minus::before { content: "\f068"; }
.fa-minus-circle::before { content: "\f056"; }
.fa-mobile-alt::before { content: "\f3cd"; }
.fa-mobile-screen-button::before { content: "\f3cd"; }
.fa-money-bill::before { content: "\f0d6"; }
.fa-money-bill-wave::before { content: "\f53a"; }
.fa-money-check-edit::before { content: "\f872"; }
.fa-money-check-pen::before { content: "\f872"; }
.fa-mortar-board::before { content: "\f19d"; }
.fa-multiply::before { content: "\f00d"; }
.fa-music::before { content: "\f001"; }
.fa-navicon::before { content: "\f0c9"; }
.fa-not-equal::before { content: "\f53e"; }
.fa-palette::before { content: "\f53f"; }
.fa-paper-plane::before { content: "\f1d8"; }
.fa-paper-plane-alt::before { content: "\e20a"; }
.fa-paper-plane-top::before { content: "\e20a"; }
.fa-paperclip::before { content: "\f0c6"; }
.fa-pen::before { content: "\f304"; }
.fa-pen-to-square::before { content: "\f044"; }
.fa-people::before { content: "\e216"; }
.fa-person::before { content: "\f183"; }
.fa-person-cane::before { content: "\e53c"; }
.fa-person-pregnant::before { content: "\e31e"; }
.fa-person-shelter::before { content: "\e54f"; }
.fa-person-walking::before { content: "\f554"; }
.fa-person-walking-with-cane::before { content: "\f29d"; }
.fa-phone::before { content: "\f095"; }
.fa-phone-arrow-right::before { content: "\e5be"; }
.fa-phone-arrow-up::before { content: "\e224"; }
.fa-phone-arrow-up-right::before { content: "\e224"; }
.fa-phone-hangup::before { content: "\e225"; }
.fa-phone-intercom::before { content: "\e434"; }
.fa-phone-laptop::before { content: "\f87a"; }
.fa-phone-missed::before { content: "\e226"; }
.fa-phone-outgoing::before { content: "\e224"; }
.fa-phone-slash::before { content: "\f3dd"; }
.fa-phone-volume::before { content: "\f2a0"; }
.fa-plus::before { content: "\2b"; }
.fa-plus-circle::before { content: "\f055"; }
.fa-power-off::before { content: "\f011"; }
.fa-print::before { content: "\f02f"; }
.fa-question-circle::before { content: "\f059"; }
.fa-random::before { content: "\f074"; }
.fa-receipt::before { content: "\f543"; }
.fa-refresh::before { content: "\f021"; }
.fa-remove::before { content: "\f00d"; }
.fa-repeat::before { content: "\f363"; }
.fa-repeat-alt::before { content: "\f364"; }
.fa-reply::before { content: "\f3e5"; }
.fa-reply-all::before { content: "\f122"; }
.fa-right-from-bracket::before { content: "\f2f5"; }
.fa-right-to-bracket::before { content: "\f2f6"; }
.fa-rotate::before { content: "\f2f1"; }
.fa-rotate-back::before { content: "\f2ea"; }
.fa-rotate-backward::before { content: "\f2ea"; }
.fa-rotate-left::before { content: "\f2ea"; }
.fa-save::before { content: "\f0c7"; }
.fa-school::before { content: "\f549"; }
.fa-screwdriver-wrench::before { content: "\f7d9"; }
.fa-search::before { content: "\f002"; }
.fa-search-minus::before { content: "\f010"; }
.fa-search-plus::before { content: "\f00e"; }
.fa-send::before { content: "\e20a"; }
.fa-share::before { content: "\f064"; }
.fa-share-all::before { content: "\f367"; }
.fa-share-alt::before { content: "\f1e0"; }
.fa-share-nodes::before { content: "\f1e0"; }
.fa-shield::before { content: "\f132"; }
.fa-shield-blank::before { content: "\f132"; }
.fa-shield-check::before { content: "\f2f7"; }
.fa-shield-slash::before { content: "\e24b"; }
.fa-shield-times::before { content: "\e24c"; }
.fa-shield-xmark::before { content: "\e24c"; }
.fa-shopping-bag::before { content: "\f290"; }
.fa-shopping-basket::before { content: "\f291"; }
.fa-shopping-cart::before { content: "\f07a"; }
.fa-shower::before { content: "\f2cc"; }
.fa-shuffle::before { content: "\f074"; }
.fa-sign-in::before { content: "\f090"; }
.fa-sign-in-alt::before { content: "\f2f6"; }
.fa-sign-out::before { content: "\f08b"; }
.fa-sign-out-alt::before { content: "\f2f5"; }
.fa-signal::before { content: "\f012"; }
.fa-signal-5::before { content: "\f012"; }
.fa-signal-perfect::before { content: "\f012"; }
.fa-signal-stream::before { content: "\f8dd"; }
.fa-signal-stream-slash::before { content: "\e250"; }
.fa-sliders::before { content: "\f1de"; }
.fa-sliders-h::before { content: "\f1de"; }
.fa-sliders-up::before { content: "\f3f1"; }
.fa-sliders-v::before { content: "\f3f1"; }
.fa-smile::before { content: "\f118"; }
.fa-sms::before { content: "\f7cd"; }
.fa-sort::before { content: "\f0dc"; }
.fa-sort-alpha-asc::before { content: "\f15d"; }
.fa-sort-alpha-desc::before { content: "\f881"; }
.fa-sort-alpha-down::before { content: "\f15d"; }
.fa-sort-alpha-down-alt::before { content: "\f881"; }
.fa-sort-alpha-up-alt::before { content: "\f882"; }
.fa-sort-alt::before { content: "\f883"; }
.fa-sort-amount-asc::before { content: "\f160"; }
.fa-sort-amount-down::before { content: "\f160"; }
.fa-sort-amount-up::before { content: "\f161"; }
.fa-sort-amount-up-alt::before { content: "\f885"; }
.fa-sort-asc::before { content: "\f0de"; }
.fa-sort-desc::before { content: "\f0dd"; }
.fa-sort-down::before { content: "\f0dd"; }
.fa-sort-up::before { content: "\f0de"; }
.fa-sort-up-down::before { content: "\e099"; }
.fa-spinner::before { content: "\f110"; }
.fa-star::before { content: "\f005"; }
.fa-stethoscope::before { content: "\f0f1"; }
.fa-subtract::before { content: "\f068"; }
.fa-sync::before { content: "\f021"; }
.fa-sync-alt::before { content: "\f2f1"; }
.fa-table::before { content: "\f0ce"; }
.fa-tablet-alt::before { content: "\f3fa"; }
.fa-tablet-screen-button::before { content: "\f3fa"; }
.fa-tag::before { content: "\f02b"; }
.fa-taxi::before { content: "\f1ba"; }
.fa-text::before { content: "\f893"; }
.fa-text-size::before { content: "\f894"; }
.fa-timer::before { content: "\e29e"; }
.fa-times::before { content: "\f00d"; }
.fa-times-circle::before { content: "\f057"; }
.fa-toggle-large-off::before { content: "\e5b0"; }
.fa-toggle-large-on::before { content: "\e5b1"; }
.fa-tools::before { content: "\f7d9"; }
.fa-trash::before { content: "\f1f8"; }
.fa-trash-alt::before { content: "\f2ed"; }
.fa-trash-can::before { content: "\f2ed"; }
.fa-triangle-exclamation::before { content: "\f071"; }
.fa-undo::before { content: "\f0e2"; }
.fa-undo-alt::before { content: "\f2ea"; }
.fa-unlink::before { content: "\f127"; }
.fa-unlock::before { content: "\f09c"; }
.fa-unsorted::before { content: "\f0dc"; }
.fa-up-right-and-down-left-from-center::before { content: "\f424"; }
.fa-upload::before { content: "\f093"; }
.fa-user::before { content: "\f007"; }
.fa-user-check::before { content: "\f4fc"; }
.fa-user-circle::before { content: "\f2bd"; }
.fa-user-friends::before { content: "\f500"; }
.fa-user-group::before { content: "\f500"; }
.fa-user-minus::before { content: "\f503"; }
.fa-user-plus::before { content: "\f234"; }
.fa-user-slash::before { content: "\f506"; }
.fa-user-times::before { content: "\f235"; }
.fa-user-xmark::before { content: "\f235"; }
.fa-users::before { content: "\f0c0"; }
.fa-vacuum::before { content: "\e04d"; }
.fa-vcard::before { content: "\f2bb"; }
.fa-video::before { content: "\f03d"; }
.fa-video-camera::before { content: "\f03d"; }
.fa-volume::before { content: "\f6a8"; }
.fa-volume-control-phone::before { content: "\f2a0"; }
.fa-volume-down::before { content: "\f027"; }
.fa-volume-high::before { content: "\f028"; }
.fa-volume-low::before { content: "\f027"; }
.fa-volume-medium::before { content: "\f6a8"; }
.fa-volume-mute::before { content: "\f6a9"; }
.fa-volume-off::before { content: "\f026"; }
.fa-volume-slash::before { content: "\f2e2"; }
.fa-volume-times::before { content: "\f6a9"; }
.fa-volume-up::before { content: "\f028"; }
.fa-volume-xmark::before { content: "\f6a9"; }
.fa-walker::before { content: "\f831"; }
.fa-walking::before { content: "\f554"; }
.fa-warning::before { content: "\f071"; }
.fa-wheelchair::before { content: "\f193"; }
.fa-wheelchair-alt::before { content: "\e2ce"; }
.fa-wheelchair-move::before { content: "\e2ce"; }
.fa-wifi::before { content: "\f1eb"; }
.fa-wifi-3::before { content: "\f1eb"; }
.fa-wifi-slash::before { content: "\f6ac"; }
.fa-wifi-strong::before { content: "\f1eb"; }
.fa-wrench::before { content: "\f0ad"; }
.fa-xmark::before { content: "\f00d"; }
.fa-xmark-circle::before { content: "\f057"; }
.fa-zap::before { content: "\f0e7"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-weight: 400; }

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-weight: 900; }

