@import "../../../styles/colors.module";
@import "../../../styles/sizes.module";

.login {
  display: flex;
  flex-direction: column;
  gap: $spacing-07;

  p.login-question {
    display: flex;
    align-items: center;
    color: $dark-blue-800;
    margin: 0;

    .icon {
      color: $pink-700;
      margin-right: $spacing-02;
    }

    b {
      margin-right: $spacing-03;
    }
  }

  .login-error-message {
    color: $error-700;
    text-align: center;
  }

  .field {
    margin: 0 !important;

    label {
      color: $dark-blue-800 !important;
    }
  }

  .login-links {
    display: flex;
    flex-direction: column;
  }
}
