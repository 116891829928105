html {
  font-size: 16px !important;
}

body {
  font-family: "Lato", serif;
  margin: 0;
  padding: 0;
}

.ui.input.disabled {
  opacity: 1;
}
