@import "../../styles/_colors.module";
@import "../../styles/_sizes.module";
@import "./default.shadows.module";

// ## Surcharge graphique pour la nouvelle charte Domiserve 2023 ##

html {
  body {
    // ## Background ##
    background-color: $background-color;

    // ## Font size ##
    font-size: $desktop-font-size;

    @media (max-width: $mobile-width) {
      font-size: $mobile-font-size;
    }

    // ## General text ##
    min-height: 100vh;
    color: $neutral-800;
    font-family: "Lato", serif;
    text-underline-offset: 0.25rem;

    .ui.red {
      border-color: $error-500 !important;
      color: $error-500 !important;
    }

    // Colors
    .red-color-2 {
      color: $error-500 !important;
    }

    // Fonts.
    .font-bold {
      font-weight: bold;
    }

    // Align
    .text-align-center {
      text-align: center;
    }

    // ## Liens ##
    a:not(.label) {
      color: $text-link-color !important;
      font-weight: 400 !important;
      line-height: 1.5rem !important;
      text-decoration: underline;
      text-decoration-color: $text-link-color !important;

      &:hover {
        color: $text-link-color;
        text-decoration: unset !important;
      }

      &:active {
        color: $text-link-active-color !important;
        text-decoration: unset !important;
      }
    }

    // ## En-têtes ##
    h1 {
      color: $secondary-color;
      font-weight: bold;
      font-size: 1.95rem;
    }
    h2 {
      color: $secondary-color;
      font-weight: bold;
      font-size: 1.56rem;
    }
    h3 {
      color: $secondary-color;
      font-weight: bold;
      font-size: 1.25rem;
    }
    h4 {
      color: $secondary-color;
      font-weight: bold;
      font-size: 1rem;
    }

    // ## Bouton Semantic UI ##

    .ui.loading.button {
      color: transparent !important;
    }

    // ## Form Semantic UI ##
    .ui.form,
    .ui.form .field > label {
      @media (max-width: $mobile-width) {
        font-size: $mobile-font-size !important;
      }
    }

    // ##  Input Semantic UI ##
    .ui.input > input,
    .ui.form textarea,
    .ui.selection.dropdown,
    .react-datepicker__input-container > input[type="text"] {
      border-radius: 0.5rem !important;
      min-height: 2.5rem;
    }

    .ui.form textarea {
      height: 3.5rem !important;
    }

    // Bordure de l'input au focus
    .ui.form input[type="text"]:focus,
    .ui input[type="text"]:focus,
    .ui.form textarea:focus,
    .ui.selection.active.dropdown {
      border-color: $light-blue-500 !important;
    }

    // Input avec icone
    .ui.icon.input > i.icon:not(.link) {
      font-size: 1rem;
      margin-right: 0.75rem;
      width: 1.5rem;
    }

    .ui.fluid.dropdown {
      .search {
        padding: inherit !important;
      }

      // Icone dans le champ dropdown
      i {
        position: absolute;
        font-size: 1rem !important;
        width: 1.5rem !important;
        height: 1.5rem !important;
        padding: 0.6rem !important;
        margin-right: 0 !important;
        float: right;
        right: 1em;
        top: 8%;
      }

      // Taille et positionnement du loading
      i::before,
      i::after {
        height: 1rem !important;
        width: 1rem !important;
        top: 45% !important;
        left: 85% !important;
      }
    }

    // Icones encerclés
    .circled {
      width: 2.25rem;
      height: 2.25rem;
      line-height: 2rem;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
      border: 2px solid $dark-blue-800;
    }

    .circled.large {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.25rem;
    }

    .circled:hover {
      background-color: $light-blue-100;
      cursor: pointer;
    }

    .circled.disabled {
      background-color: $neutral-100;
      border: 2px solid $neutral-400;
      opacity: 1;
    }

    .circled.disabled::before {
      color: $neutral-400 !important;
    }

    .circled::before {
      font-size: 1.25rem !important;
      color: $dark-blue-800;
    }

    .ui.fluid.dropdown > i:focus {
      color: black;
    }

    // Input piece jointe
    .input-file .ui.action.file.input > label:first-child {
      min-height: 2.5rem;
      border-radius: 0.5rem 0 0 0.5rem;
      font-weight: 400 !important;
      color: $neutral-400 !important;
    }

    .input-file .ui.action.file.input > label:last-child {
      border-radius: 0 0.5rem 0.5rem 0 !important;
    }
    .semantic-form-field-filled {
      .ui.input > input,
      .ui.form textarea,
      .ui.selection.dropdown {
        border-color: $neutral-300;
      }
    }

    ::placeholder {
      color: $neutral-400 !important;
      opacity: 1;
    }

    .ui.dropdown.multiple {
      height: auto;
    }

    // Style pour un dropdown button
    .ui.dropdown.button {
      border-radius: 0.5rem;
      border: 1px solid $neutral-300;
      background: $white;

      &.active.visible {
        background-color: $white;
      }

      &:hover {
        background-color: $dark-blue-50;
      }

      & > .text {
        color: $dark-blue-800;
      }

      & > .icon {
        color: $dark-blue-800;
        opacity: 1;
      }

      & > .menu {
        border-radius: 0.5rem;

        & > .item {
          margin: 0 !important;
          width: 100%;
          padding: 0.75rem 1rem 0.75rem 1rem !important;
          height: 3rem;
          align-items: center;
          display: flex;

          &.disabled {
            background: transparent !important;
            border: none !important;
          }

          &:hover {
            border-radius: 0;
            text-decoration: underline;
          }
        }
      }
    }

    .ui.default.dropdown:not(.button) > .text,
    .ui.dropdown:not(.button) > .default.text {
      color: $neutral-400 !important;
    }

    label {
      color: $field-label-color !important;
      font-size: 1rem !important;
      font-weight: 700 !important;
    }
    .field label {
      margin-bottom: 0.5rem !important;
    }
    .field .checkbox label {
      font-weight: unset !important;
    }

    // Input transparent
    .transparent,
    .transparent > input {
      background-color: transparent !important;
      border: unset !important;
      opacity: 1 !important;
      padding-left: 0px !important;
      resize: none !important;
      i,
      svg {
        display: none !important;
      }
    }

    // Input désactivé
    .ui.disabled.input > input,
    .ui.input:not(.disabled) input[disabled],
    .ui.disabled.dropdown,
    .ui.dropdown .menu > .disabled.item,
    .ui.form .disabled.field,
    .ui.form .disabled.fields .field,
    .ui.form .field :disabled,
    .ui.input:not(.disabled) input[disabled] ~ i::before {
      background-color: $neutral-100;
      color: $neutral-500 !important;
      opacity: 1;
    }

    .ui.multiple.dropdown.disabled > .label {
      color: $neutral-500 !important;
    }

    .ui.label > .close.icon,
    .ui.label > .delete.icon {
      opacity: 1 !important;
    }

    // Input en erreur
    .ui.input.error > input,
    .ui.form .field.error :not(.ui.dropdown) input[type="text"],
    .ui.form .field.error .ui.dropdown {
      background: $white !important;
      border-color: $error-500 !important;
    }

    // Input avec props "labeled", soit avec unité de mesure
    .ui.labeled.input > .label:not(.corner) {
      font-size: 1.5rem;
      font-weight: 400;
      border-radius: 0rem 0.5rem 0.5rem 0rem;
      color: $neutral-700;
      background-color: $neutral-300;
      height: calc(2.5rem + 3px);
      width: 2.5rem;
      padding: 0.5rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ui.labeled.input > .label:not(.corner)::before {
      font-size: 1.25rem !important;
    }

    .ui.labeled.input > .label:not(.corner):hover {
      background-color: $neutral-400;
    }

    .ui[class*="left labeled"].input > input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left-color: transparent !important;
    }

    .ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
      border-radius: 0.5rem 0rem 0rem 0.5rem;
    }

    // Input file
    .input-file .ui.action.file.input > label:last-child {
      font-size: 1.25rem !important;
      padding: 0.5rem;
    }

    // Date Picker Field
    .multiple-dates * > .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $light-blue-500 !important;
    }

    // ##  Liste déroulante
    .ui.active.selection.dropdown {
      border-bottom-left-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }

    .ui.upward.selection.dropdown .menu {
      border-bottom-width: 1rem !important;
    }

    .ui.selection.dropdown .menu {
      border-radius: 0.5rem !important;
      border: 1px solid #e2e7ef !important;
      background: $white;
      padding: 0.75rem 0rem 0.25rem 0rem;
      margin-top: 1px;
      margin-bottom: 1px;
    }

    .ui.active.upward.selection.dropdown {
      border-radius: 0.5rem !important;
    }

    .ui.selection.dropdown .menu > .item {
      border-top: unset !important;
    }

    .ui.selection.dropdown .menu > .item:hover {
      background-color: $neutral-100 !important;
    }

    .ui.dropdown .menu .item {
      margin: 0rem 0.5rem 0.56rem 0.5rem !important;
      border-radius: 0.5rem;
    }

    .ui.active.selection.dropdown > .dropdown.icon::before {
      transform: rotate(180deg) !important;
      display: inline-block;
    }

    .ui.upward.selection.dropdown .menu {
      border: 1px solid #e2e7ef !important;
    }

    @media only screen and (max-width: $mobile-width) {
      .ui.selection.dropdown .menu {
        max-height: 16rem !important;
      }
    }

    // Elément sélectionné
    .ui.dropdown .menu .selected.item,
    .ui.dropdown.selected {
      background-color: $light-blue-50 !important;
      color: $light-blue-600 !important;
      font-weight: normal !important;
    }

    // En erreur
    .ui.form .field.error .ui.dropdown,
    .ui.form .field.error .ui.dropdown .item,
    .ui.form .field.error .ui.dropdown > .text,
    .ui.form .fields.error .field .ui.dropdown,
    .ui.form .fields.error .field .ui.dropdown .item {
      background: $white !important;
      color: $neutral-500;
    }

    .ui.search.dropdown > input.search {
      left: 0px;
      z-index: unset;
    }

    // Case à cocher
    .ui.checkbox {
      input ~ label {
        color: $neutral-800 !important;
        margin-left: 3px;
      }

      input ~ label::before {
        transform: scale(1.3);
      }

      input:checked ~ label::before {
        background-color: $yellow-400 !important;
        border-color: $yellow-400 !important;
      }

      input:checked ~ label::after {
        color: white !important;
        font-weight: 100 !important;
      }

      label::before {
        border-color: $neutral-400 !important;
      }

      label:hover::before {
        border-color: $neutral-500 !important;
        box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
      }

      &.disabled {
        input:checked ~ label::before {
          background-color: $neutral-200 !important;
          border-color: $neutral-200 !important;
        }
        input:checked ~ label::after {
          color: $neutral-100 !important;
        }
      }
    }

    .field.small .ui.checkbox {
      input ~ label::before,
      input ~ label::after {
        transform: scale(1);
      }
    }

    .ui.disabled.checkbox > label::before {
      background-color: $neutral-100 !important;
      border-color: $neutral-300 !important;
    }

    .ui.disabled.checkbox > label {
      color: $neutral-500 !important;
    }

    // Radio
    .ui.radio.checkbox {
      input ~ label::before {
        transform: scale(1.4);
      }

      input ~ label::after {
        background-color: white !important;
        transform: scale(0.6);
      }
    }

    .field.small .ui.radio.checkbox {
      input ~ label::before {
        transform: scale(1.2);
      }
      input ~ label::after {
        transform: scale(0.5);
      }
    }

    // Toggle
    .ui.toggle.checkbox {
      label {
        margin-left: 0px;
        padding-left: 3.7rem;
      }

      input:checked ~ label::before {
        background-color: $yellow-400 !important;
      }

      input ~ label::after {
        box-shadow: unset;
        border: 0.13rem solid $neutral-700;
      }

      input:checked ~ label::after {
        box-shadow: unset;
        border: 0.13rem solid $yellow-400;
        left: 1.4rem;
      }

      input:disabled ~ label::before {
        background-color: $neutral-300 !important;
        opacity: 1 !important;
      }
    }

    .ui.toggle.checkbox label::before,
    .ui.toggle.checkbox:hover label::before {
      background-color: $neutral-700;
      width: 2.875rem;
      height: 1.5rem;
    }

    .ui.checkbox input[disabled] ~ label,
    .ui.disabled.checkbox label {
      opacity: 1 !important;
    }

    .ui.toggle.checkbox > input:disabled ~ label::after {
      border: 0.13rem solid $neutral-300;
    }

    .field.small .ui.toggle.checkbox {
      input ~ label::before,
      input ~ label::after {
        transform: scale(0.8);
      }
    }

    // ##Table ##
    .ui .table:not(.basic),
    .ui.table:not(.basic) {
      font-size: 0.875rem;
      color: $neutral-800 !important;
      th {
        background-color: $neutral-300 !important;
        border-left: 1px solid $neutral-300 !important;
        border-bottom: unset !important;
        color: $secondary-color !important;

        &:hover {
          opacity: 0.95;
        }
      }

      th {
        padding: 0.625rem 1rem;
      }

      .secondary-header {
        th {
          color: $black !important;
          background-color: $neutral-50 !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: 1px solid $neutral-200 !important;
        }
      }

      tr:hover,
      a.table-row-link:hover {
        background-color: $neutral-100 !important;
      }

      td {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid $neutral-200 !important;
      }

      border: none !important;
    }

    .table > tbody > tr > td,
    .table > tbody > tr > th {
      border: none !important;
    }

    .table > tbody > tr:nth-child(2n),
    .table > tbody > a.table-row-link:nth-child(2n),
    html .ui.striped.table > tr:nth-child(2n) {
      background-color: $neutral-main-background-color !important;
    }

    // ## Pagination ##
    .ui.pagination.menu {
      border: none;
      box-shadow: none;
      background: unset !important;
      align-items: center;

      .item {
        color: $dark-blue-800 !important  ;
        border: 1px solid $neutral-300;
        border-radius: 0.5rem;
        margin-left: 0.5rem;
        min-width: 2.0625rem !important;
        height: 2.25rem !important;
        padding: 0.5rem !important;
        justify-content: center;
        background-color: $white !important;
      }

      .item::before {
        background: none;
      }

      .item:hover {
        background-color: $neutral-100 !important;
      }

      .item.active {
        background-color: $yellow-400 !important;
        color: $white;
        border: none;
      }

      .item.disabled {
        background-color: $neutral-200 !important;
        color: $neutral-400 !important;
        border: none;
      }

      .item[type="ellipsisItem"] {
        border: none;
        width: 0.825rem !important;
        color: $neutral-300 !important;
        background-color: unset !important;
      }

      .item:first-child,
      .item:last-child {
        border-radius: inherit;
        border: none !important;
        font-size: 0.9375rem;
        background-color: unset !important;
      }
    }

    // ## TabBar ##
    $tab-bar-vertical-padding: 1rem;
    $tab-bar-border-width: 0.125rem; // 2px
    $tab-bar-active-border-width: 0.375rem; // 6px
    .tab-bar {
      border-bottom: $tab-bar-border-width solid $neutral-300 !important;
      overflow-x: clip !important;
      overflow-y: visible !important;

      .tab-bar-item {
        color: $secondary-color !important;
        padding: $tab-bar-vertical-padding 2rem !important;

        &.active {
          border-bottom: $tab-bar-active-border-width solid $yellow-400 !important;
          padding-bottom: calc(
            $tab-bar-vertical-padding - $tab-bar-active-border-width + $tab-bar-border-width
          ) !important;
        }

        &:hover {
          font-weight: 700 !important;
        }
      }
    }

    // ## Card ##
    .ui.card,
    .ui.cards > .card,
    .ui.card > * > * > .ui.table,
    .ui.card > * > * > .ui.table tr td {
      box-shadow: none;
      border: none !important;
      border-top: 0px !important;
      border-radius: 8px !important;
    }
  }

  .table > tbody > tr:nth-child(2n),
  .ui.striped.table > tr:nth-child(2n) {
    background-color: $main-color !important;
  }

  // ## Segment ##
  .segment {
    border-radius: 0.5rem !important;
    border: none !important;

    &.without-border {
      border-radius: 0 !important;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
    }
  }

  // ## Message - Notification
  .ui.message {
    border: 1px solid;
    border-radius: 0.5rem !important;
    font-size: 0.875rem !important;
    box-shadow: $shadow-m !important;
    opacity: 1 !important;

    p {
      opacity: 1 !important;
    }

    .header {
      font-size: 0.875rem !important;
    }

    &.positive {
      background-color: $success-50;
      color: $success-800;
      .header {
        color: $success-800;
      }
    }

    &.negative {
      background-color: $error-50;
      color: $error-700;
      .header {
        color: $error-700;
      }
    }

    &.warning {
      background-color: $warning-50;
      color: $warning-700;
      .header {
        color: $warning-700;
      }
    }

    .icon.close {
      opacity: 1 !important;
      font-weight: bold;
      font-size: 1.2rem !important;
    }
  }

  // ## Cookies ##
  .modal {
    .button-component-success {
      border-radius: 0.28rem !important;
      background-color: $success-500 !important;
    }
    .button-component-danger {
      border-radius: 0.28rem !important;
      background-color: $error-500 !important;
    }
  }
}

// ## Modales ##
.ui.page.dimmer.modals {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

// ## Divider ##
.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid $main-light-color;
}

// ## Breadcrum
.breadcrumb-component {
  font-size: 0.875rem;
  .breadcrumb-component-item:not(.font-bold) {
    color: $light-blue-600;
  }
}

/// ## Icons ##
/// On surcharge ici avec Font Awesome 6 Pro pour que les composants de Semantic UI React aient les nouveaux icones
i.icon {
  font-family: "Font Awesome 6 Pro" !important;
}

.ui.dropdown > .dropdown.icon::before {
  content: "\f0d7";
  font-weight: 900;
}

.fas,
.fa-solid {
  font-weight: 900 !important;
}

.far,
.fa-regular {
  font-weight: 400 !important;
}
