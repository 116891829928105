@import "../shared-components/styles/default.sizes.module";

// ## Tailles spécifiques à l'application ##

// Header mobile avec bouton menu et logo
$header-secondary-height: 6.25rem;
$header-secondary-mobile-height: 3.5rem;

// Bande de couleur en haut des pages non connectées
$header-color-height: 2.5rem;
$header-color-mobile-height: 1rem;

// Menu navigation (SideMenu)
// On augmente la largeur du composant par defaut car les labels sont longs
$menu-navigation-width: 18rem;

// Hauteur total des headers
$header-total-height: $header-height;
$header-mobile-total-height: $header-secondary-mobile-height + $header-mobile-height;
$header-disconnected-total-height: $header-color-height + $header-secondary-height;
$header-disconnected-mobile-total-height: $header-color-mobile-height + $header-secondary-mobile-height +
  $header-mobile-height;

:export {
  headerSecondaryHeight: $header-secondary-height;
  headerSecondaryMobileHeight: $header-secondary-mobile-height;
  headerColorHeight: $header-color-height;
  headerColorMobileHeight: $header-color-mobile-height;
  headerTotalHeight: $header-total-height;
  headerMobileTotalHeight: $header-mobile-total-height;
  headerDisconnectedTotalHeight: $header-disconnected-total-height;
  headerDisconnectedMobileTotalHeight: $header-disconnected-mobile-total-height;
  menuNavigationWidth: $menu-navigation-width;
}
