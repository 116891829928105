@import "../../styles/default.sizes.module";

.header-link-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.4rem 1rem;
  cursor: pointer;

  &:last-child {
    padding-right: 0;
  }

  .header-link-container-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .icon:not(.caret-icon) {
    font-size: 2rem;

    &.caret-icon {
      height: 1rem;
      padding-left: 0.3rem;
    }
  }

  &:hover {
    .header-link-container-label {
      text-decoration: underline;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 0rem 0.5rem;
    overflow: unset;

    .icon:not(.caret-icon) {
      height: 2.286rem;
    }
  }
}
