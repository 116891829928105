@import "../../styles/sizes.module";

.notifications-layout {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 600;
  width: 100%;

  .notifications-component {
    width: 50%;
    @media (max-width: $mobile-width) {
      width: 90%;
    }
  }
}
