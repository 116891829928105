@import "../../../styles/sizes.module";

.light-header {
  overflow: hidden;

  .grid {
    width: 100%;
    height: 100%;
    margin: unset;

    .row {
      padding: $spacing-03 $spacing-05;
      gap: $spacing-04;
      flex-wrap: nowrap !important;
      height: 100%;

      .column {
        padding-left: $spacing-00 !important;
        padding-right: $spacing-00 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-of-type {
          justify-content: left;
        }
        &:last-of-type {
          justify-content: right;
        }
      }
    }
  }
}
