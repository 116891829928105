@import "../../../styles/colors.module";
@import "../../../styles/sizes.module";

.register {
  display: flex;
  flex-direction: column;
  gap: $spacing-07;

  p.register-text {
    display: flex;
    align-items: center;
    color: $dark-blue-800;
    margin: 0;

    .icon {
      color: $pink-700;
      margin-right: $spacing-02;
    }

    b {
      margin-right: $spacing-03;
    }
  }

  .register-button {
    height: unset !important;
  }
}
