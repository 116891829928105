@import "../../styles/colors.module";
@import "../../styles/sizes.module";

.content-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: $mobile-width) {
    overflow: auto;
    margin: unset;
  }

  .content-layout-main {
    margin: $spacing-05;
    padding: $spacing-05;

    @media (max-width: $mobile-width) {
      margin: unset;
    }
  }

  .content-layout-footer {
    height: $footer-height;
    width: 100%;
    margin-top: auto;
    overflow: hidden;

    @media (max-width: $mobile-width) {
      height: unset;
    }
  }
}
