@import "../shared-components/styles/default.text-styles";

html {
  // ## Segment ##
  .segment {
    margin-top: $spacing-07;
    margin-bottom: $spacing-07;

    @media (max-width: $mobile-width) {
      margin-top: $spacing-05;
      margin-bottom: $spacing-05;
    }
  }

  .validation-rules + .segment {
    margin-top: $spacing-02 !important;
  }
}
