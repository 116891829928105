@import "../../styles/default.colors.module";

.button-tertiary {
  cursor: pointer;
  display: inline-flex;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;

  .icon {
    color: $text-link-color;
  }

  .ui.button {
    background-color: transparent !important;
    color: $text-link-color !important;
    padding: 8px !important;
    text-decoration-line: underline;
    text-decoration-color: $text-link-color !important;
    text-underline-offset: 0.25rem;
    font-weight: 400 !important;
  }

  &:active {
    .icon,
    .ui.button {
      transform: translateY(1px) !important;
      color: $text-link-active-color !important;
      text-decoration: unset !important;
    }
  }

  &.disabled {
    cursor: default;

    .icon,
    .ui.button,
    .ui.inline.loader {
      transform: unset !important;
      color: $text-link-disabled-color !important;
      opacity: 1 !important;
      text-decoration-line: underline !important;
      text-decoration-color: $text-link-disabled-color !important;
    }
  }

  &.no-padding {
    padding: 0;

    .ui.button {
      padding: 0 !important;
    }
  }

  .ui.inline.loader {
    vertical-align: sub;
    color: $light-blue-600;
  }
}

// On spécifie un comportement au survol seulement si le device le permet
@media (hover: hover) {
  .button-tertiary:hover:not(.disabled) > .ui.button,
  .button-tertiary:hover:not(.disabled) > i {
    opacity: unset;
    text-decoration-line: unset;
  }
}
