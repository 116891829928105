@import "../../styles/sizes.module";
@import "../../styles/colors.module";

.side-menu-layout {
  position: fixed;
  top: 0;
  bottom: 0;
  width: $menu-navigation-width;
  background-color: $secondary-light-color;
  z-index: 400;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;

  .side-menu {
    position: static;
  }

  .ui.secondary.vertical.pointing.menu,
  .ui.secondary.vertical.pointing.menu .item {
    border-right-style: none !important;
  }
}
